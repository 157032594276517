import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import './styles/Home.scss';
import IMGHomeP1 from "../assets/images/home-p1.jpeg";
import IMGHomeP2 from "../assets/images/home-p2.jpeg";
import TextImageContent from "../components/TextImageContent";

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <section className='first-view'>
        <div className='cover-image'>
          <div className='flex-container'>
            <div className='content'>
              <span className='headline'>{ t('home.first.headline') }</span>
              <div className='body'>
                <span>{ t('home.first.body') }</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='second-view'>
        <article>
          <div className='content'>
            <span className='headline'>{ t('home.second.headline') }</span>
            <span className='body'>{ t('home.second.body') }</span>
          </div>
        </article>
      </section>

      <section className='third-view'>
        <TextImageContent headline={ t('home.third.headline') }
                          body={ t('lagos.body.p1') }
                          button={ t('home.third.button') }
                          link="/lagos"
                          image={ IMGHomeP1 }
                          contentOrderClass='text-image-order'
        />
      </section>

      <section className='fourth-view'>
        <TextImageContent headline={ t('home.fourth.headline') }
                          body={ t('home.fourth.body') }
                          button={ t('home.fourth.button') }
                          link="/about"
                          image={ IMGHomeP2 }
                          contentOrderClass='image-text-order'
        />
      </section>

      <section className='fifth-view'>
        <article>
          <div className='content'>
            <span className='headline'>{ t('home.fifth.headline') }</span>
            <div className='button-content'>
              <button>
                <Link to='/booking'><div>{ t('home.fifth.button') }</div></Link>
              </button>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}

export default Home;