import React from 'react';
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import './styles/Lagos.scss';
import IMGLagos1 from "../assets/images/lagos-1.jpeg";
import IMGLagos2 from "../assets/images/lagos-2.jpeg";
import IMGLagos3 from "../assets/images/lagos-3.jpeg";
import IMGLagos4 from "../assets/images/lagos-4.jpeg";
import IMGLagos5 from "../assets/images/lagos-5.jpeg";
import IMGLagos6 from "../assets/images/lagos-6.jpeg";
import IMGLagos7 from "../assets/images/lagos-7.jpeg";
import IMGLagos8 from "../assets/images/lagos-8.jpeg";
import IMGLagos9 from "../assets/images/lagos-9.jpeg";
import IMGLagos10 from "../assets/images/lagos-10.jpeg";
import IMGLagos11 from "../assets/images/lagos-11.jpeg";
import IMGLagos12 from "../assets/images/lagos-12.jpeg";

function Lagos() {
  const { t } = useTranslation();

  return (
    <>
      <section className='introduction'>
        <div className='cover-image'>
          <div className='flex-container'>
            <div className='content'>
              <span className='headline'>Lagos</span>
            </div>
          </div>
        </div>
      </section>

      <section className='page-content'>
        <div className='text-content'>
          <span className='headline'>{ t('lagos.headline.title') }</span>

          <div className='body'>
            <div className='paragraph'>
              <span>{ t('lagos.body.p1') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('lagos.body.p2') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('lagos.body.p3') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('lagos.body.p4') }</span>
            </div>
          </div>
        </div>

        <div className='image-content'>
          <div className='content'>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos1 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos2 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos3 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos4 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos5 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos6 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos7 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos8 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos9 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos10 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos11 }
                           alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGLagos12 }
                           alt="Lagos"
              />
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default Lagos;