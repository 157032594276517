import { LazyLoadImage } from "react-lazy-load-image-component";
import React from "react";
import { Link } from "react-router-dom";

import './styles/TextImageContent.scss';

export default function TextImageContent(props) {

  return (
    <section className={props.contentOrderClass}>
      <article>
        <div className='content'>
          <div className='body-content'>
            <span className='headline'>{props.headline}</span>
            <span className='body'>{props.body}</span>
            <div className='button-content'>
              <button>
                <Link to={props.link}><div>{props.button}</div></Link>
              </button>
            </div>
          </div>

        </div>

        <div className='image-content'>
          <div className='image-container'>
            <LazyLoadImage src={props.image}
                           alt="Lagos"
            />
          </div>
        </div>
      </article>
    </section>
  );
}