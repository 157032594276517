import React from 'react';
import i18n from 'i18next';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import {format, isPast, isToday} from 'date-fns';
import pt from 'date-fns/locale/pt'
import en from 'date-fns/locale/en-GB'
import da from 'date-fns/locale/da'


export default class BookingCalendar extends React.Component {

  currentLang =  i18n?.language;

  localeLangMap = {
    pt: pt,
    en: en,
    dk: da
  };

  constructor(props) {
    super(props);

    this.customDayContent = this.customDayContent.bind(this);
    this.isBooked = this.isBooked.bind(this);
    this.createBlockedIcon = this.createBlockedIcon.bind(this);

    this.state = {
      bookedDates: [],
      dates: [{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }]
    }
  }

  componentDidMount() {
    fetch('./booked.json', {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then((res) => res.json())
    .then((data) => {
      this.setState({
        bookedDates: data.bookedDates,
      })
    })
    .catch((error) => {
      throw(error);
    });
  }

  createBlockedIcon() {
    return (
      <>
        <div
          style={{
            borderRadius: "50%",
            height: "10px",
            width: "10px",
            border: "2px solid red",
            position: "absolute",
            top: 2,
            right: 0,
          }}
        />
        <div
          style={{
            transform: "rotate(-45deg)",
            height: "10px",
            width: "2px",
            background: "red",
            position: "absolute",
            top: 4,
            right: 6,
          }}
        />
      </>
    );
}

  isBooked(day) {
    if (isPast(day) && !isToday(day)) {
      return false;
    }

    let isInRange = false;

    for (const element of this.state.bookedDates) {
      const startDateObject = element.checkin.split('-');
      const endDateObject = element.checkout.split('-');

      const startDate = new Date(+startDateObject[2], +startDateObject[1] - 1, +startDateObject[0]);
      const endDate = new Date(+endDateObject[2], +endDateObject[1] - 1, +endDateObject[0]);

      if (day >= startDate && day < endDate) {
        isInRange = true;
        break;
      }
    }

    return isInRange;
}

  customDayContent(day) {
    let extraDot = null;
    if (this.isBooked(day)) {
      extraDot = this.createBlockedIcon();
    }
    return (
      <div>
        {extraDot}
        <span>{format(day, "d")}</span>
      </div>
    )
  }

  render() {
    if (!this.state.bookedDates.length) {
      return null;
    }

    return (
      <DateRange
        locale={this.localeLangMap[this.currentLang]}
        editableDateInputs={true}
        onChange={item => this.setState({
          dates: [item.selection]
        })}
        moveRangeOnFirstSelection={false}
        dayContentRenderer={this.customDayContent}
        ranges={this.state.dates}
        minDate={new Date()}
      />
    );
  }
}
