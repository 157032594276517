import React from 'react';
import { useTranslation } from "react-i18next";

import './styles/Booking.scss';
import BookingCalendar from "../components/BookingCalendar";

function Booking() {
  const { t } = useTranslation();

  return (
    <div>
      <section className='introduction'>
        <div className='cover-image'>
          <div className='flex-container'>
            <div className='content'>
              <span className='headline'>Booking</span>
            </div>
          </div>
        </div>
      </section>

      <section className='page-content'>
        <div className='text-content'>
          <span className='headline'>{ t('booking.headline.title') }</span>

          <div className='body'>
            <div className='paragraph'>
              <span>{ t('booking.body.p1') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('booking.body.p2') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('booking.body.p3') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('booking.body.p4') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('booking.body.p5') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('booking.body.p6') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('booking.body.p7') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('booking.body.p8') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('booking.body.p9') }</span>
            </div>
            <div className='paragraph'>
              <span>* { t('booking.body.p10') }</span>
            </div>
          </div>
        </div>

        <div className='calendar-content'>
          <div className='content'>
            <BookingCalendar />
          </div>
          <span>* { t('booking.book.info') }</span>
        </div>
      </section>
    </div>
  );
}

export default Booking;