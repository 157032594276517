import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Lagos from "./pages/Lagos";
import Booking from "./pages/Booking";
import NoPage from "./pages/NoPage";

export default function App() {
  document.title = "Lagos";

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Layout /> }>
          <Route index element={ <Home /> } />
          <Route path="about" element={ <About /> } />
          <Route path="lagos" element={ <Lagos /> } />
          <Route path="booking" element={ <Booking /> } />
          <Route path="*" element={ <NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
