import React from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import './styles/Layout.scss'

function Layout() {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />

      <div className='page-content'>
        <Outlet />
      </div>

      <section className='footer'>
        <article>
          <div className='content'>
            <span className='title'>{ t('home.sixth.title') }</span>
            <span className='headline'>{ t('home.sixth.headline') }</span>
            <span className='body'>{ t('home.sixth.p1') }</span>
            <span className='body'>{ t('home.sixth.p2') }</span>
            <span className='body'>+45 5152 6649</span>
          </div>
        </article>
      </section>
    </>
  );
}

export default Layout;