import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { MdArrowDropUp } from 'react-icons/md'
import { MdArrowDropDown } from 'react-icons/md'
import i18next from 'i18next';
import { useTranslation } from "react-i18next";

import './styles/Navbar.scss';

export default function Navbar() {
  const { t } = useTranslation();

  const [isListOpen, setListOpen] = useState(false);

  const list = [
    { id: 1, iconClass: 'fi fi-pt', lang: 'pt' },
    { id: 2, iconClass: 'fi fi-gb', lang: 'en' },
    { id: 3, iconClass: 'fi fi-dk', lang: 'dk' },
  ];

  const [selectedItem, setSelectedItem] = useState(list[0]);

  const toggleList = () => {
    setListOpen(!isListOpen);
  }

  const selectListItem = (item) => {
    setSelectedItem(item);
    i18next.changeLanguage(item.lang);
    toggleList();
  }

  const clickEvent = useRef(null);

  const closeOpenDropdown = (e) => {
    if (clickEvent.current && isListOpen && !clickEvent.current.contains(e.target)) {
      setListOpen(false)
    }
  };

  document.addEventListener('mousedown', closeOpenDropdown);

  return (
    <nav>
      <ul>
        <li>
          <Link to='/'>{ t('nav.home') }</Link>
        </li>
        <li>
          <Link to='/lagos'>{ t('nav.lagos') }</Link>
        </li>
        <li>
          <Link to='/about'>{ t('nav.about') }</Link>
        </li>
        <li>
          <Link to='/booking'>{ t('nav.booking') }</Link>
        </li>
        <li className='language-dropdown'>
          <span className='language-label'>{ t('nav.language') }: </span>
          <div className='dropdown' ref={clickEvent}>
            <button type='button' className='dropdown-button' onClick={toggleList}>
              <div className='translation-flag'>
                <span className={selectedItem.iconClass}></span>
              </div>
              <div className='caret'>
                {isListOpen ? (
                  <MdArrowDropUp/>
                ) : (
                  <MdArrowDropDown/>
                )}
              </div>
            </button>

            {isListOpen && (
              <div className="dropdown-list">
                {list.map((listItem) => (
                  <button key={listItem.id} className="dropdown-item" onClick={() => selectListItem(listItem)}>
                    <div className='translation-flag'>
                      <span className={listItem.iconClass}></span>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        </li>
      </ul>
    </nav>
  );
}
