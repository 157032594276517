import React from 'react';
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import './styles/About.scss';
import IMGAbout1 from "../assets/images/about-1.jpg";
import IMGAbout2 from "../assets/images/about-2.jpg";
import IMGAbout3 from "../assets/images/about-3.jpg";
import IMGAbout4 from "../assets/images/about-4.jpg";
import IMGAbout5 from "../assets/images/about-5.jpg";
import IMGAbout6 from "../assets/images/about-6.jpg";
import IMGAbout7 from "../assets/images/about-7.jpg";
import IMGAbout8 from "../assets/images/about-8.jpg";
import IMGAbout9 from "../assets/images/about-9.jpg";
import IMGAbout10 from "../assets/images/about-10.jpg";
import IMGAbout11 from "../assets/images/about-11.jpg";
import IMGAbout12 from "../assets/images/about-12.jpg";
import IMGAbout13 from "../assets/images/about-13.jpg";
import IMGAbout14 from "../assets/images/about-14.jpeg";
import IMGAbout15 from "../assets/images/about-15.jpeg";
import IMGAbout16 from "../assets/images/about-16.jpeg";
import IMGAbout17 from "../assets/images/about-17.jpeg";
import IMGAbout18 from "../assets/images/about-18.jpeg";
import IMGAbout19 from "../assets/images/about-19.jpeg";
import IMGAbout20 from "../assets/images/about-20.jpeg";
import IMGAbout21 from "../assets/images/about-21.jpeg";
import IMGAbout22 from "../assets/images/about-22.jpeg";
import IMGAbout23 from "../assets/images/about-23.jpeg";
import IMGAbout24 from "../assets/images/about-24.jpeg";

function About() {
  const { t } = useTranslation();

  return (
    <>
      <section className='introduction'>
        <div className='cover-image'>
          <div className='flex-container'>
            <div className='content'>
              <span className='headline'>{ t('home.fourth.headline') }</span>
            </div>
          </div>
        </div>
      </section>

      <section className='page-content'>
        <div className='text-content'>
          <div className='body'>
            <div className='paragraph'>
              <span>{ t('about.body.p1') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('about.body.p2') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('about.body.p3') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('about.body.p4') }</span>
            </div>
            <div className='paragraph'>
              <span>{ t('about.body.p5') }</span>
            </div>
          </div>
        </div>

        <div className='image-content'>
          <div className='content'>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout1 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout2 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout3 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout4 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout5 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout6 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout7 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout8 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout9 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout10 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout11 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout12 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout13 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout14 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout15 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout16 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout17 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout18 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout19 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout20 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout21 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout22 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout23 }
                             alt="Lagos"
              />
            </div>
            <div className='image'>
              <LazyLoadImage src={ IMGAbout24 }
                             alt="Lagos"
              />
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default About;